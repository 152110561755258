import { render, staticRenderFns } from "./TemplateIndexProduct.vue?vue&type=template&id=77f78d02&scoped=true&"
import script from "./TemplateIndexProduct.vue?vue&type=script&lang=js&"
export * from "./TemplateIndexProduct.vue?vue&type=script&lang=js&"
import style0 from "./TemplateIndexProduct.vue?vue&type=style&index=0&id=77f78d02&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77f78d02",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnTab: require('/home/php1/m-nuxt/components/YfnTab.vue').default,YfnList: require('/home/php1/m-nuxt/components/YfnList.vue').default,YfnImage: require('/home/php1/m-nuxt/components/YfnImage.vue').default,YfnSwiper: require('/home/php1/m-nuxt/components/YfnSwiper.vue').default})
