
import fnApi from '/utils/common/methods'
import TemplateHead from './components/TemplateHead'
import TemplateSwiper from './components/TemplateSwiper'
import TemplateVideo from './components/TemplateVideo'
import TemplateTitle from './components/TemplateTitle'
import TemplateSpecial from './components/TemplateSpecial'
import TemplateList from './components/TemplateList'
import TemplateTopic from './components/TemplateTopic'
import TemplatePoint from './components/TemplatePoint'
import TemplateCoupon from './components/TemplateCoupon'
import TemplateIndexImage from './components/TemplateIndexImage'
import TemplateIndexDiamond from './components/TemplateIndexDiamond'
import TemplateIndexHot from './components/TemplateIndexHot'
import TemplateIndexProduct from './components/TemplateIndexProduct'
import TemplateIndexReview from './components/TemplateIndexReview'

export default {
    name: 'TemplateIndex',
    components: {
        TemplateHead,
        TemplateSwiper,
        TemplateVideo,
        TemplateTitle,
        TemplateSpecial,
        TemplateList,
        TemplateTopic,
        TemplatePoint,
        TemplateCoupon,
        TemplateIndexImage,
        TemplateIndexDiamond,
        TemplateIndexHot,
        TemplateIndexProduct,
        TemplateIndexReview
    },
    async asyncData({$api, cookies, env, route, ...context}) {
        const url = `${env.NODE_ENV == 'pre' ? 'http://mmall.preview.selleroa.com' : 'https://m.yfn.com'}${route.path}`;
        const response = await $api.template.getTemplate({
            data: JSON.stringify(url)
        });
        const res = response.result || {};
        return {
            seo: res,
            info: res
        }
    },
    props: {},
    data() {
        return {
            skeleton: true,
            fnApi: fnApi,
            query: {},
            middleEmit: {},
            hasApp: false,
            info: {}, // 所有配置数据
            pageSet: {}, // 页面配置数据,
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {
        // this.getIndexAjax()
        this.setPageSetData()
    },
    methods: {
        // 获取自定义数据
        getIndexAjax() {
            this.$api.template.getTemplate({
                data: JSON.stringify(this.query.pageUrl)
            }).then(response => {
                // type 1-页面设置 2-轮播图 3-视频 4-标题 5-专题入口 6-商品列表 7-主题商品 8-电梯 9-优惠卷
                const res = response.result || {};
                this.info = res
                this.skeleton = false
                this.setPageSetData()
            })
        },
        // 页面配置数据
        setPageSetData() {
            const list = this.info.templateList || [];
            const target = list.filter(p => {
                return p.type == 1
            })[0] || {}
            this.pageSet = target
            this.pageSet.style = {}
            this.pageSet.style.background = target.content?.color || '#ffffff'
        },
        // 跳转
        jumpLink(obj) {
            if(this.hasApp) {
                this.fnApi.bridgeApp.bridge({
                    name: 'goCategory',
                    params: {...obj}
                })
            } else {
                obj.jumpUrl && (window.location.href = obj.jumpUrl)
            }
        },
        // SEO HEAD
        setHead() {
            const firstList = this.seo.templateList[1] || {}
            const content = firstList.content || {}
            const image = content.picUrl || 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            const head = this.$ctxApi.seoHead.initial({
                title: this.seo.seoTitle,
                keywords: this.seo.seoTitle,
                description: this.seo.seoRemark,
                image
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {
            this.query = this.$route.query
            this.middleEmit.fn = (fn, options) => {
                this[fn] && this[fn](options)
            }
            if(process.client) {
                this.query.pageUrl = window.location.href
                this.hasApp = this.fnApi.bridgeApp.getApp().isApp
            }
        }
    },
}
