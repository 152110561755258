
import fnApi from '/utils/common/methods'

export default {
    name: 'Sustainability',
    components: {},
    async asyncData({ $api, cookies, ...context }) { },
    data() {
        return {
            fnApi: fnApi,      // 通用方法
            sustainList:[{
                img:'https://cdn2.selleroa.com/yfn-upload/review/1699235398654.jpg',
                title:this.$translate('Our responsibility'),
                text:this.$translate('Sustainability should permeate every aspect of our lives and we want to integrate it into everything we do.By avoiding large inventories and reducing waste, YFN Jewelry hopes to minimize the negative impact on our beautiful planet. In fact, we only craft, cut, engrave, and coat your jewelry after we receive your order. Just to make sure nothing goes to waste.'),
            },{
                img:'https://cdn2.selleroa.com/yfn-upload/review/1698980842472.jpg',
                title:this.$translate('Production and recycling'),
                text1:this.$translate('From 1998 to 2014, the YFN jewelry factory provided ODM services for many European and American brands. Many brands favor its exquisite manufacturing technology. In 2015,'),
                text2:this.$translate('YFN Jewelry Online Mall'),
                text3:this.$translate('was officially established. We use our production advantages to provide customers with personalized, customized services and can achieve delivery in a short time. We guarantee to repurchase the jewelry produced by YFN to ensure a healthy "life cycle."'),
            },{
                img:'https://cdn2.selleroa.com/yfn-upload/review/1698980844425.jpg',
                title:this.$translate('Daily life'),
                text:this.$translate('We have introduced personalized cups, plates, and cutlery for employees to replace with 100% eco-friendly alternatives to reduce the waste of single-use household items; We have installed recycling bins across our headquarters to encourage proper waste disposal; In addition, we have introduced digital Signature systems eliminate the need for physical signatures and reduce paper waste.'),
            },{
                img:'https://cdn2.selleroa.com/yfn-upload/review/1698980846076.jpg',
                title:this.$translate('Looking forward to 2028'),
                text:this.$translate("Through the Life Cycle Plan, we hope to achieve our goal of obtaining 100% recycled material by 2028. While solving climate change won't be easy, YFN Jewelry is committed to maximizing its impact and making the world a healthy, beautiful place."),
            },]
        }
    },
    head() {
        return this.setHead()
    },
    created() {
    },
    mounted() {
    },
    methods: {
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('The core value of YFN Jewelry is sustainability'),
                keywords: this.$translate('The core value of YFN Jewelry is sustainability'),
                description: this.$translate('Sustainability should permeate every aspect of our lives, and we craft, cut, engrave and coat your jewelry only after we receive your order, so there is no waste.'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1698738279781.png'
            });
            return {
                ...head
            }
        },
    },
}
