
export default {
    name: 'TemplateSwiper',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        info: Object
    },
    data() {
        return {
            rsInfo: {
                visible: false,
                list: []
            },
            swiperCompo: {
                name: '',
                options: {
                    hasPag: true
                }
            }
        }
    },
    computed: {},
    watch: {
        info: {
            handler(n, o) {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        setData() {
            const n = JSON.parse(JSON.stringify(this.info));
            this.rsInfo = {...this.rsInfo, ...n, ...n?.content}
            this.rsInfo.list = n.content.bannerArr || []
            this.rsInfo.visible = !!this.rsInfo.list.length
            this.swiperCompo.name = `TemplateSwiper-${n.moduleId}`
        }
    },
}
