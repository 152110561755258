
export default {
    name: 'TemplatePoint',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        info: Object,
        middleEmit: Object
    },
    data() {
        return {
            rsInfo: {
                visible: false,
                list: []
            },
            tab: {
                id: ''
            }
        }
    },
    computed: {},
    watch: {
        info: {
            handler(n, o) {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        // 数据设置
        setData() {
            const n = JSON.parse(JSON.stringify(this.info));
            this.rsInfo = {...this.rsInfo, ...n, ...n?.content}
            this.rsInfo.list = this.rsInfo.liftType == 1 ? this.rsInfo.imageData || [] : this.rsInfo?.fontList?.fontList || []
            this.rsInfo.visible = !!this.rsInfo.list.length
            if(this.rsInfo.liftType != 1){
                this.tab = {...this.tab, ...this.rsInfo.fontData}
                this.tab.type = this.tab.checkbgColor ? 'card' : 'line'
            }
            this.tab.id = this.rsInfo.list[0]?.point
        },
        // tab
        changeTab(e) {
            this.$VueScrollTo.scrollTo(`.${e}`, '', {
                offset: -50
            })
        }
    },
}
