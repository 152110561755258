
export default {
    name: 'TemplateList',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        info: Object,
        middleEmit: Object
    },
    data() {
        return {
            hasLoadMore: false,
            rsInfo: {
                visible: false,
                list: []
            },
            page: {
                pageSize: 20,
                pageNum: 1,
                totalPage: 10
            },
            tab: {}
        }
    },
    computed: {},
    watch: {
        info: {
            handler(n, o) {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        // 数据设置
        setData() {
            const n = JSON.parse(JSON.stringify(this.info));
            this.rsInfo = {...this.rsInfo, ...n, ...n?.content}
            this.rsInfo.list = this.rsInfo.setType == 1 ? this.rsInfo.imageData : this.rsInfo.list
            this.rsInfo.setType == 1 && !this.hasLoadMore && this.loadMore()
        },
        // 获取商品数据
        getCategoryProductAjax() { // 分组商品
            this.$api.category.getCategoryList({
                pageSize: this.page.pageSize,
                pageNum: this.page.pageNum,
                categoryId: this.tab.categoryId,
            }).then(response => {
                const res = response.result || {};
                this.page.totalPage = res.totalPage
                this.rsInfo.list = [...this.rsInfo.list, ...res.list]
            })
        },
        getProductAjax() { // 组件商品
            this.$api.template.getProductList({
                pageSize: this.page.pageSize,
                pageNum: this.page.pageNum,
                componentId: this.rsInfo.moduleId,
            }).then(response => {
                const res = response.result || {};
                this.page.totalPage = res.totalPage
                this.rsInfo.list = [...this.rsInfo.list, ...res.list]
            })
        },
        // 跳转
        handleImg(obj) {
            window.location.href = obj.jumpUrl
        },
        // 触底加载
        loadMore() {
            this.$nextTick(() => {
                this.$fnApi.pullRefresh.load((e) => {
                    this.hasLoadMore = true
                    this.emitBottomLoading(e)
                }, {
                    target: `.TemplateList-img--${this.rsInfo.$i}`,
                })
            })
        },
        emitBottomLoading(e, callBack) {
            const hasNext = this.page.totalPage > this.page.pageNum;
            if(e.isIntersecting && hasNext) {
                this.page.pageNum += 1
                if(this.rsInfo.chooseproType == 1) {
                    this.getCategoryProductAjax()
                } else {
                    this.getProductAjax()
                }
            }
            callBack(!hasNext)
        }
    },
}
