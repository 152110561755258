
export default {
    name: 'TemplateIndexProduct',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        allData: Object,
        data: Object,
        info: Object,
    },
    data() {
        return {
            rsData: {},
            rsInfo: {},
            list: [],
            tabProduct: {         // tab商品
                visible: false,
                id: '',
                list: [],
                lineWidth: '',
                lineHeight: '',
            },
            page: {
                pageNum: 1,
                pageSize: 20,
                totalPage: 10,
            }
        }
    },
    computed: {},
    watch: {
        info: {
            handler(n, o) {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        // 设置数据
        setData() {
            const info = JSON.parse(JSON.stringify(this.info))
            const data = JSON.parse(JSON.stringify(this.data.data))
            this.rsData = data
            this.rsInfo = info
            this.rsInfo.rs = {}
            this.rsInfo.rs.ref = `swiper${this.rsInfo.i}`
            if(process.client) {
                this.setStyle()
            }
            this.setTab()
            this.setSwiperOptions()
            this.setRCData()
        },
        setRCData() {
            let rs = this.rsInfo.rs;
            let list = this.list;
            // showType -> 1 横向展示 2 纵向展示
            // addGoodsType -> 1 手动添加商品 2 选择分组 3 系统推荐 4 销量排行榜
            // multipleTab -> true 是否tab类型商品
            if(this.rsInfo.addGoodsType != 3) {
                list = this.rsData.productList || []
            } else {
                list = this.rsData.recommendList || []
            }
            // 横向添加more
            let rsList = list.filter(item => {
                return !item.hasMore
            });
            // tab模式
            if(this.rsInfo.multipleTab) {
                const filterTabProduct = this.tabProduct.list.filter(p => {
                    return p.tabId == this.tabProduct.id
                })
                const firstList = filterTabProduct[0] || {};
                rsList = firstList.productList || []
            }
            if(this.rsInfo.jumpUrl && this.rsInfo.showType == 1) {
                rsList = [...rsList, ...[{hasMore: true}]]
            }

            this.list = rsList
            rs.length = this.list.length
            rs.visible = !!rs.length
            this.setBestIcon()
        },
        // style
        setStyle() {
            const unit = 'px'
            let rs = this.rsInfo.rs;
            let iW = window.innerWidth > 540 ? 540 : window.innerWidth
            let w = Math.ceil(iW * 0.295);
            rs.slideStyle = {
                width: w + unit,
            }
            rs.modelStyle = {
                'background-color': this.rsInfo.backgroundColor || '#fff'
            }
        },
        // 设置tab
        setTab() {
            this.tabProduct.list = []
            if(this.rsInfo.multipleTab) {
                this.tabProduct.list = this.data?.data?.tabDataList || [];
                const firstTabDataList = this.tabProduct.list[0] || {};
                this.tabProduct.id = firstTabDataList.tabId
                // rsList = firstTabDataList.productList || []
                this.tabProduct.visible = this.tabProduct.list.length > 1
                // tab样式
                this.tabProduct.lineWidth = !this.rsInfo.selectedUnderline ? 0 : ''
                this.tabProduct.lineHeight = this.tabProduct.lineWidth
                this.tabProduct.list.forEach(p => {
                    p.props = {}
                    p.props['title-style'] = {
                        'font-size': `${this.rsInfo.titleFontSize}px`,
                        'font-family': this.rsInfo.titleFontFamily
                    }
                })
            }
        },
        // 设置畅销榜icon
        setBestIcon() {
            if(this.rsInfo.addGoodsType == 4) {
                const icon = ['https://cdn2.selleroa.com/yfn-upload/review/1689238925974.png',
                    'https://cdn2.selleroa.com/yfn-upload/review/1689238928885.png',
                    'https://cdn2.selleroa.com/yfn-upload/review/1689238931013.png'];
                this.list.forEach((item, i) => {
                    if(i < 3) {
                        item.bestIconUrl = icon[i]
                    }
                })
            }
        },
        // swiper
        setSwiperOptions() {
            let rs = this.rsInfo.rs;
            rs.options = {}
            rs.options.freeMode = true
            rs.options.slidesPerView = 'auto'
        },
        // tab
        changeTab() {
            this.setRCData()
        },
        // 跳转
        jumpLink() {
            window.location.href = this.rsInfo.jumpUrl
        },
        // 触底加载
        getProductAjax() {
            this.$api.template.getProductRecList({
                index: 1,
                moduleId: this.data.moduleId,
                templateId: this.allData.id,
                pageNum: this.page.pageNum,
                pageSize: this.page.pageSize,
            }).then(response => {
                const res = response.result;
                this.list = [...this.list, ...(res.recommendList || res.list || [])]
            })
        },
        emitBottomLoading(e, callBack) {
            const hasNext = this.rsInfo.addGoodsType == 3 && this.page.totalPage > this.page.pageNum;
            if(e.isIntersecting && hasNext) {
                this.page.pageNum += 1
                this.getProductAjax()
            }
            callBack(!hasNext)
        }
    },
}
