
export default {
    name: 'TemplateHead',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        info: Object
    },
    data() {
        return {
            headCompo: {
                hasAdvertise: true,
                hasNotify: true,
                hasNav: true
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
}
