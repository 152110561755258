
export default {
    name: 'TemplateVideo',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        info: Object
    },
    data() {
        return {
            rsInfo: {
                visible: false,
                list: []
            },
            swiperCompo: {
                name: ''
            }
        }
    },
    computed: {},
    watch: {
        info: {
            handler(n, o) {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        setData() {
            const n = JSON.parse(JSON.stringify(this.info));
            this.rsInfo = {...this.rsInfo, ...n}
            this.rsInfo.list = n.content.list || []
            this.rsInfo.visible = !!this.rsInfo.list.length
            this.swiperCompo.name = `TemplateSwiper-${n.$i}`
            this.rsInfo.list.forEach((item, index) => {
                item.cover = item.cover || `${item.videoUrl}?x-oss-process=video/snapshot,t_1,f_jpg,w_750,m_fast`
                item.url = item.videoUrl
                item.controls = false
                item.showVideoIcon = true
                item.index = n.$i
                item.targetIndex = index
                item.refs = `video-${n.$i}`
                item.ref = `video-${n.$i}-${index}`
            })
        },
        handleVideo(obj) {
            this.$set(this.rsInfo.list, obj.targetIndex, {...this.rsInfo.list[obj.targetIndex], controls: true, showVideoIcon: false})
            let clear = setTimeout(() => {
                // 视频播放
                this.$refs[obj.ref].play()
                clearTimeout(clear)
            }, 0)
        }
    },
}
