
export default {
    name: 'TemplateTopic',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        info: Object,
        middleEmit: Object
    },
    data() {
        return {
            rsInfo: {
                visible: false,
                list: []
            },
        }
    },
    computed: {},
    watch: {
        info: {
            handler(n, o) {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        // 数据设置
        setData() {
            const n = JSON.parse(JSON.stringify(this.info));
            this.rsInfo = {...this.rsInfo, ...n, ...n?.content}
            this.rsInfo.list = this.rsInfo.setType == 1 ? this.rsInfo.imageData : this.rsInfo.commonData.list
            if(this.rsInfo.setType == 2) {
                this.rsInfo.list.forEach(p => {
                    p.title = p.productName
                    p.pic = p?.pics[0]?.url
                    p.displaySalePrice = p.salePrice / 100
                    p.orgPrice = 0
                })
            }
        },
    },
}
