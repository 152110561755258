
export default {
    name: 'TemplateIndexHot',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        data: Object,
        info: Object
    },
    data() {
        return {
            rsInfo: {},
            list: []
        }
    },
    computed: {},
    watch: {
        info: {
            handler(n, o) {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        // 设置数据
        setData() {
            const info = JSON.parse(JSON.stringify(this.info))
            this.rsInfo = info
            this.rsInfo.rs = {}
            this.list = this.rsInfo.hotspotList || []
            this.rsInfo.rs.visible = this.list.length
            // 埋点设置
            if(process.client) {
                this.list.forEach((item, i) => {
                    item.buried = window.btoa(JSON.stringify({
                        plateId: this.rsInfo.spmId,
                        pitId: i + 1,
                        pitIdData: {
                            jumpUrl: item.jumpUrl
                        }
                    }))
                })
            }
            this.setStyle()
        },
        // style
        setStyle() {
            const unit = 'px', percent = '%';
            const rs = this.rsInfo.rs;
            rs.modelStyle = {
                // height: this.rsInfo.height + unit,
                padding: `0 ${this.rsInfo.margins || 0}${unit}`,
                'background-color': this.rsInfo.backgroundColor,
                'box-sizing': 'border-box',
                'margin': `0 ${this.rsInfo.margins || 0}${unit}`
            }
            const wComp = this.rsInfo.calculatedWidth;
            const hComp = this.rsInfo.calculatedHeight;
            this.list.forEach(item => {
                const tl = item.location.tl;
                const w = this.setNumPad(item.width / wComp) * 100 + percent
                const h = this.setNumPad(item.height / hComp) * 100 + percent
                const y = this.setNumPad(tl.y / hComp) * 100 + percent
                const x = this.setNumPad(tl.x / wComp) * 100 + percent
                const hotStyle = {
                    width: w,
                    height: h,
                    top: y,
                    left: x,
                };
                item.hotStyle = hotStyle
            })
        },
        // 保留两位小数
        setNumPad(val) {
            return +(val.toFixed(4))
        },
        // 跳转
        jumpLink(obj) {
            window.location.href = obj.jumpUrl
        }
    }
}
