import { render, staticRenderFns } from "./TemplateIndexReview.vue?vue&type=template&id=43be962c&scoped=true&"
import script from "./TemplateIndexReview.vue?vue&type=script&lang=js&"
export * from "./TemplateIndexReview.vue?vue&type=script&lang=js&"
import style0 from "./TemplateIndexReview.vue?vue&type=style&index=0&id=43be962c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43be962c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnImage: require('/home/php1/m-nuxt/components/YfnImage.vue').default,YfnRate: require('/home/php1/m-nuxt/components/YfnRate.vue').default,YfnButton: require('/home/php1/m-nuxt/components/YfnButton.vue').default,YfnSwiper: require('/home/php1/m-nuxt/components/YfnSwiper.vue').default})
