
export default {
    name: 'TemplateIndexReview',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        data: Object,
        info: Object
    },
    data() {
        return {
            rsInfo: {},
            list: []
        }
    },
    computed: {},
    watch: {
        info: {
            handler(n, o) {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        // 设置数据
        setData() {
            // sizeType -> 1 自定义 2 自适应
            const info = JSON.parse(JSON.stringify(this.info))
            this.rsInfo = info
            this.rsInfo.rs = {}
            this.list = this.rsInfo.commentList || []
            if(process.client) {
                // 埋点设置
                this.list.forEach((item, i) => {
                    item.buried = window.btoa(JSON.stringify({
                        plateId: this.rsInfo.spmId,
                        pitId: i + 1,
                    }))
                })
            }
            this.rsInfo.rs.visible = this.list.length
            this.rsInfo.rs.hasOnlyOne = this.list.length < 2
            this.rsInfo.rs.ref = `swiper${this.rsInfo.i}`
            // 设置图片数据
            this.setImgOptions()
            // swiper数据
            this.setSwiperOptions()
        },
        // 设置图片数据
        setImgOptions() {
            let length = this.list.length
            this.list.forEach((item, i) => {
                item.lazyLoad = true
                item.showLoading = true
                if((!i || i == length - 1) && length > 1) {
                    item.lazyLoad = false
                    item.showLoading = false
                }
            })
        },
        // 设置swiper数据
        setSwiperOptions() {
            let rs = this.rsInfo.rs;
            rs.options = {}
            rs.slideStyle = {}
            rs.modelStyle = {}
            // 切换
            rs.options.loop = true
            if(this.rsInfo.automaticCarousel) {
                rs.options.autoplay = {
                    delay: this.rsInfo.carouselInterval * 1000 || 3000,
                    stopOnLastSlide: true,
                    disableOnInteraction: false
                }
            }
            // style
            if(this.rsInfo.backgroundColor) {
                rs.modelStyle.background = this.rsInfo.backgroundColor
            }
            // 分页
            if(!rs.hasOnlyOne && this.rsInfo.pagination) {
                rs.options.hasPag = true
            }
        },
        // 跳转
        jumpLink(obj) {
            obj.productCode && (window.location.href = `/${obj.seoUrl}`)
        }
    }
}
