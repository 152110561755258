
export default {
    name: 'TemplateSpecial',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        info: Object,
        middleEmit: Object
    },
    data() {
        return {
            rsInfo: {
                visible: false,
                list: []
            }
        }
    },
    computed: {},
    watch: {
        info: {
            handler(n, o) {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        setData() {
            const n = JSON.parse(JSON.stringify(this.info));
            this.rsInfo = {...this.rsInfo, ...n, ...n?.content}
            this.rsInfo.list = this.rsInfo.imageData
        },
        jumpLink(obj) {
            this.middleEmit.fn('jumpLink', obj)
        }
    },
}
